.myModal {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: 10px !important; /* Firefox */
}
::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important; /*this affects the size of horizontal scrool*/
}
::-webkit-scrollbar-track {
  background-color: #ddd;
  border-radius: 100vw;
  margin-block: 1em;
}
::-webkit-scrollbar-thumb {
  border: 2px solid #ddd;
  background: #bbb;
  border-radius: 100vw;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}
